import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14cada62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row gx-5" }
const _hoisted_2 = { class: "col-4 col-xl-3 sidebar" }
const _hoisted_3 = { class: "col-8 col-xl-9 overview" }
const _hoisted_4 = { class: "row row-list gx-5" }
const _hoisted_5 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_OverviewCriteria = _resolveComponent("OverviewCriteria")!
  const _component_OverviewLegend = _resolveComponent("OverviewLegend")!
  const _component_OverviewTable = _resolveComponent("OverviewTable")!
  const _component_OverviewControls = _resolveComponent("OverviewControls")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('products'),
        mb: 2,
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "onRefresh"]),
      _createVNode(_component_AlertError2, {
        error: this.productsOverviewUI.getError()
      }, null, 8, ["error"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_OverviewCriteria, {
            onReloadedContent: this.onCriteriaInitialized,
            onSubmit: this.reloadContent
          }, null, 8, ["onReloadedContent", "onSubmit"]),
          _createVNode(_component_OverviewLegend, {
            "is-for-pharmacist": "",
            class: "mt-4"
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_OverviewTable, {
                "products-overview": this.productsOverview,
                "has-products": this.hasProducts,
                ui: this.productsOverviewUI
              }, null, 8, ["products-overview", "has-products", "ui"]),
              _createVNode(_component_OverviewControls, {
                "products-overview": this.productsOverview,
                "has-products": this.hasProducts,
                "is-layout-toggle-supported": false,
                "is-csv-download-supported": false,
                class: "mt-3",
                onChange: this.onControlsChanged
              }, null, 8, ["products-overview", "has-products", "onChange"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}